import { Grid, Typography } from "@mui/material";
import Image from 'mui-image';
import image_project from '../assets/introduction.jpeg';
import image_circle_upstairs from '../assets/circle_upstairs.png';;

export default function Introduction() {
    return <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
        <Grid lg={7} md={7} sm={7} xl={7} xs={12} sx={{ padding: 3, '@media (max-width: 600px)': {padding: 0} }}>
          <Grid sx={{ padding: 3, '@media (max-width: 600px)': {padding: 0}}}>
          <Typography 
            sx={{ 
              fontFamily: 'Raleway:wght@700',
              fontWeight: 700,
              fontSize: '150%',
            }}
          >
          Deliver on a sure footing
          </Typography>
            <Typography
            sx={{ 
              fontFamily: 'Raleway:wght@500',
              fontWeight: 500,
              fontSize: '100%',
              paddingTop: 5,
              '@media (max-width: 600px)': {paddingTop: 2}
            }}
          >
          Upstairs Labs provides turn key solutions and a wide range of
          engineering services, specializing in the Linux embedded space,
          continuous integration and delivery, big data, functional web
          platforms and artificial intelligence.<br/><br/>

          With decades of engineering experience in upstream Open Source
          development in our portfolio, we pride ourselves on delivering
          excellence through pragmatic consideration of your business needs,
          and remaining diligent in every step.
          </Typography>
          </Grid>
        </Grid>
        <Grid lg={5} md={5} sm={5} xl={5} xs={0} 
              sx={{ 
                paddingBottom: 20,
                paddingRight: 20,
                paddingLeft: 20,
                paddingTop: 5,
                '@media (max-width: 600px)': {display: 'none'}
              }}
        >
          <Image src={image_circle_upstairs} fit='contain'/>
        </Grid>
      </Grid>
      <Grid container rowSpacing={1}>
      	<Grid 
      		lg={12} md={12} sm={12} xl={12} xs={12} 
                sx={{ 
                  padding: 3,
                  '@media (max-width: 600px)': {paddingTop: 10, paddingBottom: 0, paddingLeft: 0, paddingRight: 0}
                }}
        >
        	<Typography 
		    sx={{ 
		      fontFamily: 'wght:Raleway@700',
		      fontWeight: 700,
		      fontSize: '150%',
		    }}
		  >
		  Projects
		  </Typography>
        	<Typography 
            sx={{ 
              fontFamily: 'wght:Raleway@500',
              fontWeight: 500,
              fontSize: '100%',
              paddingTop: 5,
              '@media (max-width: 600px)': {paddingTop: 2}
            }}
          >
          In addition to our continued collaboration and contributions to a wide
          variety of upstream projects, we have also open sourced some of our
          own intellectual property in the interest of empowering our clients
          and furthering the interests of the wider open source community.
          </Typography>
        </Grid>
      	
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} 
        sx={{ 
          paddingTop: 10, 
          paddingBottom: 10,
          '@media (max-width: 600px)': {padding: 0}
        }}
      >
          <Grid lg={3} md={3} sm={3} xl={3} xs={12} 
                sx={{ 
                  padding: 5,
                  '@media (max-width: 600px)': { paddingTop: 10, paddingBottom:0, paddingRight: 0, paddingLeft: 0, width: '80%', height: '80%' }
                }}
          >
            <Image src={image_project} fit='contain'/>
          </Grid>
        <Grid lg={9} md={9} sm={9} xl={9} xs={12} sx={{ padding: 3, '@media (max-width: 600px)': {padding: 0} }}>
          <Grid sx={{ padding: 3, '@media (max-width: 600px)': {padding: 0} }}>
            <Typography
            sx={{ 
              fontFamily: 'Raleway:wght@500',
              fontWeight: 500,
              fontSize: '100%',
              paddingTop: 5,
              '@media (max-width: 600px)': {paddingTop: 2}
            }}
          >
          Theater is an ensemble of tools including a tiny portable runtime
          library and a graphical user interface which allows for rapid
          application development of highly deterministic software using
          statecharts.
          </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
}
