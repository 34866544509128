import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import logoNavy from '../assets/upstairslabs_main_navy.png';
import logoWhite from '../assets/upstairslabs_main_white.png';
import Introduction from "./Introduction";
import { Grid } from "@mui/material";
import Footer from "./Footer";

interface Props {
  window?: () => Window;
}

const drawerWidth = 180;
// const navItems = ["Home", "Projects", "News"];
const navItems = [""];

export default function Main(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography sx={{ my: 2 }}>
        <img src={logoNavy} style={{ maxWidth: '100px' }}/>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Grid container>
        <Grid xs={12} sx={{display: "flex", height: "100%", verticalAlign: "middle" }}>
          <Box>
            <CssBaseline />
            <AppBar component="nav" sx={{ backgroundColor:'#0E253D' }}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  component="a"
                  href="/index.html"
                  sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                >
                  <img src={logoWhite} style={{ maxWidth: '120px', padding: 5 }}/>
                </Typography>
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  {navItems.map((item) => (
                    <Button key={item} sx={{ color: "#fff" }}>
                      {item}
                    </Button>
                  ))}
                </Box>
              </Toolbar>
              </AppBar>
              <Box component="nav">
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  }}
                >
                  {drawer}
                </Drawer>
              </Box>
          </Box>
          <Box component="main" sx={{ padding: 5, display: "inline", height: "100%", verticalAlign: "middle" }}>
              <Toolbar />
              <Introduction />
              <Footer />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
