import { Info, Security } from "@mui/icons-material";
import { AppBar, CssBaseline, Grid, Link, Toolbar, Typography } from "@mui/material";
import image_upstairslabs_main_navy from '../assets/upstairslabs_main_navy.png'

export default function Footer() {
    return <>
    <Grid container xs={12} sx={{ paddingTop: 10, paddingBottom: 5 }}>
        <CssBaseline />
        <AppBar position="static" elevation={0} component="footer" 
            sx={{ 
                background: "#fff",
                '@media (max-width: 600px)': {display: 'none'}
            }}
        >
            <Toolbar style={{ justifyContent: "center" }}>
                <Typography variant="caption" 
                    sx={{ 
                        fontSize: '100%',
                        color: "black",
                        margin: 1,
                        '@media (max-width: 600px)': {fontSize: '30%'}
                    }}
                >
                    📧 info@upstairslabs.com
                </Typography>
                <Typography variant="caption" 
                    sx={{ 
                        fontSize: '100%',
                        color: "black",
                        margin: 1,
                        '@media (max-width: 600px)': {fontSize: '30%'}
                    }}
                >
                    📞 +82-10-9294-7993
                </Typography>
                <Typography variant="caption" 
                    sx={{ 
                        fontSize: '100%',
                        color: "black",
                        margin: 1,
                        '@media (max-width: 600px)': {fontSize: '30%'}
                        }}
                >
                    © 2023 Upstairs Labs LLC
                </Typography>
            </Toolbar>
        </AppBar>
        <AppBar position="static" elevation={0} component="footer" 
            sx={{ 
                background: "#fff",
                '@media (min-width: 599px)': {display: 'none'}
            }}
        >
            <Toolbar style={{ justifyContent: "center" }}>
                <Typography variant="caption" 
                    sx={{ 
                        fontSize: '60%', 
                        color: "black", 
                        margin: 1,
                        textAlign: 'center',
                        '@media (max-width: 600px)': {fontSize: '70%'}
                    }}
                >
                    📧 info@upstairslabs.com <br/>
                    📞 +82-10-9294-7993 <br/>
                    © 2023 Upstairs Labs LLC
                </Typography>
            </Toolbar>
        </AppBar>
    </Grid>
</>
    
}
